<template>
  <div
    class="app-command-palette"
    :class="{ 'app-command-palette--selectable': selectable }"
  >
    <div
      class="app-command-palette__search flex-col"
      @click="toggleDropdown"
      ref="dropdown"
      v-if="hideInput"
    >
      <div
        class="inline-block text-sm font-medium leading-6 text-primaryG-700 mb-0"
        v-if="selectable && selected.length"
      >
        {{ label }}
      </div>
      <div class="flex flex-wrap w-full bg-white">
        <div
          class="app-input app-input--icon relative"
          :class="{ 'app-input--gray': garyBackground }"
          tabindex="0"
        >
          <div
            :class="{ 'flex border rounded-lg flex-wrap': selected.length > 0 }"
          >
            <div
              v-if="selected.length && !showSelectedBelow"
              class="p-2 flex flex-wrap gap-y-2"
            >
              <span
                class="bg-uilight px-2 py-1 rounded-full mr-2 flex items-center normal-case"
                v-for="(selectedItem, index) in selected"
                :key="index"
              >
                <slot name="selected-item" :selected-item="selectedItem">
                  <span>{{ getDisplayName(selectedItem) }}</span>
                </slot>
                <i
                  @click="() => handleItemClick(selectedItem)"
                  class="mdi mdi-close-circle app-button__icon !ml-1 cursor-pointer"
                ></i>
              </span>
            </div>
            <div
              class="app-command-palette__input"
              :class="{
                'app-command-palette__input--has-value': selected.length,
              }"
            >
              <BaseInput
                :type="'search'"
                :name="'search'"
                :leading="icon"
                :placeholder="placeholder"
                :label="selected.length ? null : label"
                :helpText="helpText"
                :selectedItems="selected"
                @input="
                  ;(searchValue = $event.target.value),
                    $emit('search', searchValue)
                "
                :value="searchValue"
                @delete-item="handleItemClick"
                class="w-full"
                id="command"
                :disabled="disabled"
                :data-testid="'search-option-' + label"
              >
                <!-- <template v-slot:append>
            
            </template> -->
              </BaseInput>
            </div>
          </div>
        </div>
      </div>

      <!-- show the selected items below the search box -->
      <div
        v-if="selected.length && showSelectedBelow"
        class="py-2 flex flex-wrap gap-1.5"
      >
        <div
          v-for="(selectedItem, index) in selected"
          :key="index"
          class="flex items-center gap-x-2"
        >
          <BaseBadge
            rounded
            closable
            :label="
              selectedItem[objValue] +
              (selectedItem.count ? ' (' + selectedItem.count + ')' : '')
            "
            @close="handleItemClick(selectedItem)"
          ></BaseBadge>
        </div>
      </div>

      <div v-show="dropdownIsOpen">
        <!-- Loading -->
        <div
          v-if="isLoading"
          tabindex="0"
          class="dropdown-content app-command-palette__dropdown z-[80]"
        >
          <div
            role="status"
            class="w-full p-4 space-y-4 divide-y divide-gray-200 animate-pulse md:p-6"
          >
            <div class="flex items-center justify-between">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
            </div>

            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <ul
          tabindex="0"
          class="dropdown-content app-command-palette__dropdown z-[80]"
          v-else-if="showItems.length"
        >
          <li
            v-for="(item, index) in showItems"
            :key="index"
            class="app-command-palette__item"
            @click="handleItemClick(item)"
          >
            <div>
              <div v-if="listIcon">
                <i :class="[`mdi mdi-${listIcon} text-xl`]"></i>
              </div>

              <slot v-if="customList" name="item" :item="item">
                <div class="app-command-palette__content">
                  <div class="app-command-palette__content__text">
                    {{ item }}
                  </div>
                </div>
              </slot>
              <div v-else>
                <div
                  class="app-command-palette__content"
                  v-if="listType === 'collection'"
                >
                  <div class="app-command-palette__content__text">
                    <strong class="app-command-palette__content__text__title">{{
                      item.colName
                    }}</strong>
                    ({{ item.colId }})
                  </div>
                  <div class="app-command-palette__content__note">
                    {{ item.nrOfDocuments }} {{ $t('Documents') }}
                  </div>
                </div>
                <div
                  class="app-command-palette__content"
                  v-else-if="listType === 'document'"
                >
                  <div class="app-command-palette__content__text">
                    <strong class="app-command-palette__content__text__title">{{
                      item.title
                    }}</strong>
                    ({{ item.docId }})
                  </div>
                  <div class="app-command-palette__content__note">
                    {{ item.nrOfPages }} {{ $t('Pages') }}
                  </div>
                </div>
                <div v-else class="app-command-palette__content">
                  <div class="app-command-palette__content__text">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <span
        class="bg-uilight px-2 py-1 rounded-full mr-2 flex items-center justify-between normal-case"
      >
        {{ selected[0].colName || selected[0] }}
        <i
          @click="handleItemClick(selected[0])"
          class="mdi mdi-close-circle app-button__icon !ml-1 cursor-pointer"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import Confirmation from '../Modals/Confirmation.vue'

export default {
  name: 'CommandPalette',
  components: {
    Confirmation,
  },
  data() {
    return {
      selected: this.initialSelected || [],
      searchValue: '',
      dropdownIsOpen: false,
      closeDropdown: false,
      hideInput: true,
      langItemIndex: 0,
    }
  },
  computed: {
    isCollection() {
      return this.listType === 'collection'
    },
    isDocument() {
      return this.listType === 'document'
    },
    showItems() {
      let flatArr = this.selected.map(tag => {
        if (typeof tag === 'object' && tag[this.objValue]) {
          return tag[this.objValue]
        } else {
          return tag
        }
      })

      return this.items.filter(val => {
        if (val[this.objValue]) {
          return !flatArr.includes(val[this.objValue])
        } else {
          return !flatArr.includes(val)
        }
      })
    },
  },
  props: {
    showSelectedBelow: {
      type: Boolean,
      default: false,
    },
    garyBackground: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    // If used as taginput with objects transmit value to match on
    objValue: {
      type: String,
      default: 'value',
    },
    helpText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'magnify',
    },
    label: {
      type: String,
      default: '',
    },
    listIcon: {
      type: String,
      default: null,
    },
    listType: {
      type: String,
      default: null,
    },
    customList: {
      type: Boolean,
      default: false,
    },
    allowNew: {
      type: Boolean,
      default: false,
    },
    initialSelected: {
      type: Array,
      default: () => [],
    },
    emitSelection: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideAfterSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    keepOneItem: {
      type: Boolean,
      default: false,
    },
    isLanguage: {
      type: Boolean,
      default: false,
    },
    clearAfterSelect: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const eventListeners = [
      { event: 'del lang confirmed', callback: this.removeLanguage },
    ]

    eventListeners.forEach(({ event, callback }) => {
      this.$bus.on(event, callback)
    })
  },
  destroyed() {
    const eventListeners = ['del lang confirmed']

    eventListeners.forEach(event => {
      this.$bus.off(event)
    })
  },
  watch: {
    initialSelected: {
      handler(value) {
        this.selected = value
        if (this.hideAfterSelected) {
          this.hideInput = !value.length
        }
      },
      deep: true,
    },
    // selected: {
    //   handler(value) {
    //     console.log("selected")
    //     console.log(value)
    //   },
    //   deep: true,
    // },
  },
  methods: {
    handleItemClick(item) {
      if (!this.selectable) {
        this.processItemClick(item)
      } else {
        // Add item to selected array if not already present
        const itemIndex = this.selected.findIndex(
          selectedItem => selectedItem === item
        )
        if (itemIndex === -1) {
          this.selected.push(item)
        } else {
          // Remove item from the selected array if it's clicked twice
          if (this.keepOneItem && this.selected.length === 1) {
            this.$emit('warning')
            return
          } else {
            if (this.isLanguage) {
              this.confirmLanguageDelete(itemIndex)
            } else {
              this.selected.splice(itemIndex, 1)
            }
          }
        }
        this.$emit('selected', this.selected)
        this.dropdownIsOpen = false
        if (this.clearAfterSelect) {
          this.searchValue = ''
        }
      }
    },
    confirmLanguageDelete(itemIndex) {
      this.langItemIndex = itemIndex
      this.modal = {
        modalContent: {
          component: Confirmation,
          props: {
            title: {
              text: this.$t('Delete Language'),
            },
            text: this.$t(
              'If you remove this language, the translations you have inputted will be permanently deleted. Would you still like to remove the language?'
            ),
            type: 'danger',
            button: {
              label: 'Delete Language',
              icon: 'delete',
            },
          },
          events: {
            confirm: () => {
              this.removeLanguage()
            },
          },
        },
      }
      this.$bus.emit('open-modal', { modal: this.modal })
    },
    removeLanguage() {
      this.selected.splice(this.langItemIndex, 1)

      this.$emit('selected', this.selected)
      this.dropdownIsOpen = false
    },
    getDisplayName(item) {
      if (this.isCollection) {
        return `${item.colName} (${item.colId})`
      } else if (this.isDocument) {
        return `${item.title} (${item.docId})`
      } else {
        return item
      }
    },
    processItemClick(item) {
      if (this.isCollection) {
        this.handleListAction(
          this.emitSelection ? 'selected' : 'goToCollection',
          item
        )
      } else if (this.isDocument) {
        this.handleListAction(
          this.emitSelection ? 'selected' : 'goToDocument',
          item
        )
      }
    },
    handleListAction(action, item) {
      if (action === 'selected') {
        this.$emit('selected', item)
      } else if (action === 'goToCollection') {
        this.goToCollection(item)
      } else if (action === 'goToDocument') {
        this.goToDocument(item)
      }
    },
    goToCollection(item) {
      if (this.$route.path.includes('/training')) {
        this.$bus.emit('trainingCollection', item.colId)
      } else {
        const collectionPath = '/collection/' + item.colId
        this.$router.push(this.localePath(collectionPath))
      }
      this.closeDropdown = true
    },
    goToDocument(item) {
      const documentPath =
        '/collection/' +
        item.collectionList.colList[0].colId +
        '/doc/' +
        item.docId

      this.$router.push(this.localePath(documentPath))
      this.closeDropdown = true
    },
    toggleDropdown() {
      if (!this.dropdownIsOpen) {
        document.addEventListener('click', this.closeDropdownOnClickOutside)
      } else if (this.closeDropdown) {
        this.dropdownIsOpen = false
        this.closeDropdown = false
        document.removeEventListener('click', this.closeDropdownOnClickOutside)
      }
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$refs?.dropdown?.contains(event.target)) {
        this.dropdownIsOpen = false
        document.removeEventListener('click', this.closeDropdownOnClickOutside)
      } else {
        this.dropdownIsOpen = true
      }
    },
  },
}
</script>
